import './Home.css';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import AboutUs from './about';
import Gallery from './gallery';
import Blog from './Blog';
import Highlights from './highlights';


export default function Content () {
    const [isExpanded, serIsExpanded] = useState(false);

    const toggleExpansion = () => {
        serIsExpanded(!isExpanded);
    }

    useEffect (() => {
        AOS.init()
    },[])

    
    return(
        <div className='bg-white' >
            <div className='container-fluid '>
                    <div className='row bg-white position-relative rightScroll' data-aos="fade-right" data-aos-delay="200" data-aos-easing="linear" data-aos-duration="1000">
                        <img className='col col-md-8 mt-3 img-fluid image-border' src="./images/first-bg.jpeg" alt="Villa Image" />
                        <img className='col-4 col-md-3 overlay-img dot-line' src="./images/ver-dot.png" alt="Dot-line" />
                        <img className='col-3 col-md-2 overlay-img line' src="./images/L-line.png" alt="Line" />
                        <div className='col col-md-4 mt-3'>
                            <p className='text-blue display-4'>Vallimayil Properties</p>
                            <p className='text-yellow display-1'>Row-Villas</p>
                             <div className={`text ${isExpanded ? 'expanded' : ''}`}>
                                <p className='text-secondary'>Welcome to our real estate company! We are dedicated to making your dreams of home ownership a reality. With years of experience and a commitment to excellence, we provide top-notch services in buying, selling, and investing in real estate. Our team of professionals is here to guide you through every step of your real estate journey. We understand that a home is not just a place; it's a lifestyle. Whether you're a first-time buyer or a seasoned investor, we are here to help you find the perfect property. Trust us to make your real estate goals a success.</p>
                            </div>
                            <div className='border border-dark border-3 stripe'></div>
                            <div className='row justify-content-around'>
                                <img className='col-5 br-5' src="./images/u-circle.png" alt="design" />
                                <button onClick={toggleExpansion} className='read-more col-5 col-md-4 h-25 align-self-center text-white '>{isExpanded ? 'Read Less' : 'Read More'}</button>
                            </div>
                        </div>
                    </div>
                <hr />
                <AboutUs/>
                <Gallery/>
                <Highlights/>
                <Blog/>
            </div> 
            
        </div>
    );
};