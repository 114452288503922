import { useEffect } from 'react';
import './gallery.css';
import AOS from 'aos';
import "aos/dist/aos.css"

export default function Gallery () {

    useEffect (() => {
        AOS.init()
    },[])

    return(
        <div>
            <div className="container-fluid bg-white p-3 p-md-5 position-relative">
                <p className='display-1 text-center' data-aos="fade-down" data-aos-delay="200" data-aos-easing="linear" data-aos-duration="1000" style={{color:"rgba(253, 161, 34, 1)", fontWeight: "600"}}>GALLERY</p>
                <div id="carouselExampleIndicators"  className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                        <img src="./images/first-bg.jpeg" className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                        <img src="./images/slide-2.jpeg" className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                        <img src="./images/slide-3.jpeg" className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                        <img src="./images/slide-4.jpeg" className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                        <img src="./images/slide-5.jpeg" className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div>
                    <img className="col-3 col-md-2 overlay-img sqr-2" src="./images/up-line.png" alt="" />
                    <img className="col-3 col-md-2 overlay-img yel-line" src="./images/yel-line.png" alt="" />
                </div>
            </div>
        </div>
);

};