import './App.css';
import Navbar from './navbar';
import AboutUs from './about';
import Home from './Home';
import Gallery from './gallery';
import Footer from './footer';
import Highlights from './highlights';
import Blog from './Blog';
import SendMail from './SendMail';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

    const countryCode = '+91';
    const phoneNumber = '8428842815';
    const handleWhatsapp = () => {
    const whatsappURl = `https://wa.me/${countryCode}${phoneNumber}`;
    window.open(whatsappURl, '_blank');
    };

  return (
    <Router> 
      <div className='whatsapp'>
        <button  onClick={handleWhatsapp}><img className='img-fluid' src='./images/whatsapp-icon.png' alt='Whatsapp' /></button>
      </div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>}  />
        <Route path='/about' element={<AboutUs/>}  />
        <Route path='/gallery' element={<Gallery/>}  />
        <Route path='/highlights' element={<Highlights/>}  />
        <Route path='/blog' element={<Blog/>}  />
        <Route path='/sendMail' element={<SendMail />}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
