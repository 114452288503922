import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faSquareInstagram, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

export default function Footer () {
    const openFacebook = () => {
        window.open('https://www.facebook.com/vmvilas', '_blank');
      };
    
      const openInstagram = () => {
        window.open('https://www.instagram.com/vmvilasinsta/', '_blank');
      };
    return (
        <div className='container-fluid pt-5 bg-white foot-background' style={{marginTop:"-50px"}}>
            <div className="p-5" style={{backgroundColor: "rgba(24, 54, 56, 1)"}}>
                <div className="row justify-content-around">
                    <div className="text-white col-md-4">
                        <p className="h3">Vallimayil Properties</p>
                        <p className="h4">Redhills </p>
                        <p className="h5">Near: Venkateshwara medical college</p>
                        <p className="h5">gated community</p>
                        <p className="h5">Chennai-600067</p>
                        <p className="h5"><FontAwesomeIcon icon={faPhone} />+91 84288 42815</p>
                    </div>
                    <div className="container-fluid text-white col-md-6 text-md-end">
                        <p className="h2">Follow Us</p>
                        <ul className="list-inline">
                            <li className="list-inline-item h2" onClick={openFacebook} ><FontAwesomeIcon icon={faSquareFacebook} size="2xl" style={{color: "#ffffff", cursor:"pointer"}} /></li>
                            <li className="list-inline-item h2" onClick={openInstagram} ><FontAwesomeIcon icon={faSquareInstagram} size="2xl" style={{color: "#ffffff", cursor:"pointer"}} /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className=" container-fluid bg-white">
                <footer className="text-center">
                    <p class="p-3 fw-light">&copy; 2023 vmvillas.com Vallimayil Propertie| Privacy Policy | Disclaimer | Terms & Conditions </p>
                </footer>
            </div>
        </div>
    )
};