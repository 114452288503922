import { useEffect } from "react";
import AOS from 'aos';
import "aos/dist/aos.css"

export default function Highlights () {

    useEffect (() => {
        AOS.init()
    },[])

    return(
        <div>
            <div className="container-fluid bg-white">
                <div className='row p-1 p-md-3 mt-5'>
                    <video controls data-aos="fade-right" data-aos-delay="200" data-aos-easing="linear" data-aos-duration="1000" className='col-12 col-md-7'>
                        <source src="./images/houseVideo.mp4" type='video/mp4'/>
                    </video>
                    <div className=' col col-md-5 pt-2'>
                        <img data-aos="fade-left" data-aos-delay="400" data-aos-easing="linear" data-aos-duration="1000" className='img-fluid w-100' src="./images/plan.png" alt="Plan" />
                    </div>
                </div>
                <div className='row p-1 p-md-3 mt-5'>
                    <div className='col col-md-7'>
                        <img data-aos="fade-right" data-aos-delay="200" data-aos-easing="linear" data-aos-duration="1000" className='img-fluid' src="./images/road-map.jpeg" alt="Root Map" />
                    </div>
                    <ul data-aos="fade-left" data-aos-delay="400" data-aos-easing="linear" data-aos-duration="1000" className='col-md-4 pt-2 p-5'>
                        <p className='h4 mt-3' style={{color: 'rgba(253, 161, 34, 1)'}}>Neighbouring Area</p>
                        <li>Vandalur to Minjur Bye-pass-500 meter</li>
                        <li>GNT Road-300 meter</li>
                        <li>Venkateswara Medical College & Hospital - 300 meter</li>
                        <li>Velammal International School - 5 km</li>
                        <li>Our site fully sourended by residential houses</li>
                        <li>Drinking water at 30 feet depth</li>
                        <p className='h4 mt-3' style={{color: 'rgba(253, 161, 34, 1)'}}>Amenities</p>
                        <li>Gated Community</li>
                        <li>Solar Lamp</li>
                        <li>Street Light</li>
                        <li>CCTV Camera</li>
                        <li>24/7 Security</li>
                        <li>24</li>
                        <li>Water Tank</li>
                    </ul>
                </div>
            </div>
        </div>
    )
};