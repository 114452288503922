import { useState, useEffect } from "react";
import React from "react";
import AOS from 'aos';
import "aos/dist/aos.css";
import './Blog.css'

export default function Blog () {

    const [isExpanded, serIsExpanded] = useState(false);
    const [isExpandedSec, serIsExpandedSec] = useState(false);
    const [isExpandedThird, serIsExpandedThird] = useState(false);
    const [isExpandedFouth, serIsExpandedFouth] = useState(false);

    const toggleExpansion = () => {
        serIsExpanded(!isExpanded);
    }

    const toggleExpansionSec = () => {
        serIsExpandedSec(!isExpandedSec);

    }

    const toggleExpansionThird = () => {
        serIsExpandedThird(!isExpandedThird);
    }

    const toggleExpansionFouth = () => {
        serIsExpandedFouth(!isExpandedFouth);
    }

    useEffect (() => {
        AOS.init()
    },[])


    return(
        <div className="container-fluid bg-white">
            <div className="justify-content-around blog mt-4">
                <div className="blog-bg p-2" data-aos="fade-up" data-aos-duration="3000">
                    <img className="img-fluid" src="./images/blog-1.jpeg" alt="Redhills Map" />
                    <p className="h3 text-center mt-1" style={{color: 'rgba(253, 161, 34, 1)'}}>Chennai Redhills <br /> features</p>
                    <p className={`text ${isExpanded ? 'expanded' : ''}`}>Redhills is a neighborhood in Chennai, India, known for its distinct features and characteristics. Here are some of the notable features and aspects of Redhills: 

                    Red Hills Lake (Puzhal Lake): This reservoir is a prominent landmark in the area. You can enjoy the view of the lake and take a leisurely walk along its banks. 
                    Natural Beauty: Redhills is situated in a serene and picturesque environment with a variety of lakes and greenery, making it an attractive place for nature lovers. 
                    Red Hills Lake: The Red Hills Lake, also known as Puzhal Lake, is a significant water body in the area. It is a source of freshwater for Chennai and provides a beautiful backdrop. 
                    Residential Areas: The locality features a mix of residential areas, including independent houses, apartments, and gated communities, making it suitable for different housing preferences.
                    Connectivity: Redhills is well-connected to the rest of Chennai through various roads and highways, making it accessible to the city center and surrounding areas.</p>
                    <hr />
                    <button className='text-center w-50 p-1' style={{backgroundColor: "rgba(253, 161, 34, 1)"}} onClick={toggleExpansion}>{isExpanded ? 'Read Less' : 'Read More'}</button>
                </div>
                <div className="blog-bg p-2" data-aos="fade-up" data-aos-duration="3000">
                <img className="img-fluid" src="./images/blog-2.jpeg" alt="Redhills Map" />
                    <p className="h3 text-center mt-4" style={{color: 'rgba(253, 161, 34, 1)'}}>Why Pick a villa <br /> in Redhills?</p>
                    <p className={`text ${isExpandedSec ? 'expanded' : ''}`}>Why one might consider buying a villa in Redhills?Certainly, here are some reasons to consider purchasing a villa in Redhills
                    Scenic Beauty : Redhills is known for its picturesque surroundings, offering a peaceful and green environment away from the city's hustle and bustle.
                    Affordability: Property prices in Redhills may be more reasonable compared to other parts of Chennai, making it an attractive option for budgetconscious buyers. 
                    Spacious Living: Villas in Redhills typically offer larger living spaces, providing ample room for families and personal customization.
                    Serene Lifestyle: Redhills offers a quieter, more suburban lifestyle, which can be a welcome change for those seeking tranquility.
                    Investment Potential: The area has shown promise in terms of real estate appreciation, potentially making it a sound long-term investment. 
                    Proximity to Amenities: Redhills is not too far from essential amenities, schools, hospitals, and shopping centers, ensuring convenience for residents.</p>
                    <hr />
                    <button className='text-center w-50 p-1' style={{backgroundColor: "rgba(253, 161, 34, 1)"}} onClick={toggleExpansionSec}>{isExpandedSec ? 'Read Less' : 'Read More'}</button>

                </div>
                <div className="blog-bg p-2" data-aos="fade-up" data-aos-duration="3000">
                <img className="img-fluid" src="./images/blog-3.jpeg" alt="Redhills Map" />
                    <p className="h3 text-center mt-1" style={{color: 'rgba(253, 161, 34, 1)'}}>Roof Gardens <br/> Features</p>
                    <p className={`text ${isExpandedThird ? 'expanded' : ''}`}>Improved Aesthetics: Roof gardens enhance
                        the visual appeal of buildings, adding greenery
                        and a natural touch to urban landscapes.
                        Environmental Benefits:
                        Heat Reduction: Green roofs absorb sunlight
                        and reduce the urban heat island effect, helping
                        to cool buildings and the surrounding area.
                        Air Quality: Plants on roofs can filter and
                        purify the air by capturing pollutants and
                        releasing oxygen.
                        Stormwater Management: Green roofs can
                        absorb rainwater, reducing the risk of flooding
                        and helping manage stormwater runoff.
                        Temperature Regulation: Green roofs can moderate indoor temperatures, making buildings
                        more comfortable year-round.
                        Biodiversity: Roof gardens can provide habitat for birds, insects, and other wildlife, contributing
                        to urban biodiversity.
                        Noise Reduction: Plants and soil can help dampen noise, making the indoor environment
                        quieter, especially in urban areas.</p>
                    <hr />
                    <button className='text-center w-50 p-1' style={{backgroundColor: "rgba(253, 161, 34, 1)"}} onClick={toggleExpansionThird}>{isExpandedThird ? 'Read Less' : 'Read More'}</button>

                </div>
                <div className="blog-bg p-2" data-aos="fade-up" data-aos-duration="3000">
                <img className="img-fluid" src="./images/blog-4.jpeg" alt="Redhills Map" />
                    <p className="h3 text-center mt-1" style={{color: 'rgba(253, 161, 34, 1)'}}>Reasons to invest <br /> in a Villa</p>
                    <p className={`text ${isExpandedFouth ? 'expanded' : ''}`}>Location and Infrastructure:
                        Chennai offers a well-developed
                        infrastructure with good road networks,
                        public transportation, and proximity to
                        important amenities.
                        Real Estate Appreciation:
                        Historically, real estate in Chennai has
                        shown steady appreciation in value, making
                        it a potentially lucrative investment.
                        Spacious Living:
                        Villas in Chennai offer more space than apartments, providing a comfortable and
                        luxurious living experience.
                        Privacy and Exclusivity:
                        Villas offer greater privacy and exclusivity compared to apartments or shared
                        housing.
                        Land Ownership:
                        Owning a villa often means owning the land it's built on, which can be a valuable
                        asset.
                        Amenities:
                        Many villa communities in Chennai come with top-notch amenities such as
                        swimming pools, gyms, clubhouses, and landscaped gardens.
                        Customization: Villas can often be customized to your preferences, allowing
                        you to create your dream home.
                        Resale Value:
                        Villas tend to hold their value well and can be attractive to future buyers.
                        Quality of Life:
                        Chennai offers a good quality of life with access to healthcare, education, and a
                        variety of cultural and recreational activities.</p>
                    <hr />
                    <button className='text-center w-50 p-1' style={{backgroundColor: "rgba(253, 161, 34, 1)"}} onClick={toggleExpansionFouth}>{isExpandedFouth ? 'Read Less' : 'Read More'}</button>
                </div>
                
            </div>
        </div>
    )
};