import React, { useState } from "react";
import './SendMail.css';

const SendMail = () => {
    const [formData, setFormData] = useState({ subject: "", body: "" });

    const validateForm = (event) => {
        const { subject, body } = formData;
        window.location.href = `mailto:Palani2222@yahoo.in?subject=${subject}&body=${body}`;
        event.preventDefault();
        setTimeout(() => {
            window.location.reload();
        }, 500); 
        return false;
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    return (
        <div className="container-fluif bg-white pb-5 ">
            <div className="heading p-3 p-md-3 send-mail">
                <h1 className="text-center" style={{ color: "rgba(253, 161, 34, 1)" }}>
                    MAIL FORM
                </h1>
                <div className="row">
                    <div className="col sm-8 offset">
                        <form
                            className="justify-content-center"
                            onSubmit={validateForm}
                            target="_blank"
                            method="GET"
                        >
                            <div className="form-group form-group-lg">
                                <label className="skills input-Form" htmlFor="subject">
                                    <strong className="h4" style={{ color: "rgba(253, 161, 34, 1)" }}>
                                        Name
                                    </strong>
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    className="form-control mt-1 text-primary bg-light"
                                    required
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group form-group-lg mt-5 mt-md-2">
                                <label className="skills input-Form" htmlFor="body">
                                    <strong className="h4" style={{ color: "rgba(253, 161, 34, 1)" }}>
                                        Feedback
                                    </strong>
                                </label>
                                <textarea
                                    id="body"
                                    rows="5"
                                    className="form-control mt-1 bg-light text-primary"
                                    required
                                    onChange={handleInputChange}
                                />
                            </div>
                            <input
                                type="submit"
                                value="Send"
                                id="navigation"
                                className="send-burtton mt-3 mt-md-2"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendMail;
