import React, { useState, } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import './navbar.css';



export default function Navbar () {

    const [isMobile, setISMobile] = useState(false);

    return(
        <div>
            <nav className='container-fluid nav-bar'>
                <img className='img-logo' src="./images/logo.jpeg" alt="" />
                <h3 className='logo'>VM Villas</h3>    
                <ul className={isMobile ? 'nav-links-mob' : 'nav-links'} onClick={() => setISMobile(false)}>
                    <Link to="/" className='home'><li>Home</li></Link>
                    <Link to="/about" className='about'><li>About Us</li></Link>
                    <Link to="/gallery" className='gallery'><li>Gallery</li></Link>
                    <Link to="/Highlights" className='plan'><li>Highlights</li></Link>
                    <Link to="/blog" className='blog-nav'><li>Blog</li></Link>
                    <Link to="/sendMail" className='sendMail'><li>Contact Us</li></Link>
                </ul>
                <button className='mob-menu-icon' onClick={() => setISMobile(!isMobile)}>
                    {isMobile ? <i><FontAwesomeIcon icon={faXmark} style={{color: "#fda122",}} /></i> : <i ><FontAwesomeIcon icon={faBars} style={{color: "#fda122",}}/></i> }
                </button>
            </nav>
        </div>
    )
};