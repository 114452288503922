import { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css"

export default function AboutUs () {

    const [isExpanded, serIsExpanded] = useState(false);

    const toggleExpansion = () => {
        serIsExpanded(!isExpanded);
    }

    useEffect (() => {
        AOS.init()
    },[])


    return(
        <div className='container-fluid bg-white'>
                <div className='mt-5' data-aos="fade-left" data-aos-delay="400" data-aos-easing="linear" data-aos-duration="1000">
                    <div className='row position-relative'>
                        <div className='col-12 col-md-4'>
                            <p className='text-yellow display-1'>About-Us</p>
                            <p className='text-blue display-5'>Welcome to <br /> VM-villas World</p>
                            <div className={`text ${isExpanded ? 'expanded' : ''}`}>
                                <p className='text-secondary'>At Vallimayil Properties, we strongly believes that real estate isn't just about building structures; it's about making places where people and communities can do well. We focus on building lasting relationships with our clients and partners. We understand that a successful real estate project isn't only about making buildings, but about creating places where people can live, work, and grow. Our strong commitment to nurturing these connections, right from the start, is at the heart of our mission. We want to change the way we think about real estate, making it a way to improve lives and build thriving communities.</p>
                            </div>
                            <hr className='border border-dark border-3'/>
                            <button className='text-center h5 w-75 p-1' style={{backgroundColor: "rgba(253, 161, 34, 1)"}} onClick={toggleExpansion}>{isExpanded ? 'Read Less' : 'Read More'}</button>
                        </div>
                        <div className='col-12 col-md-8 position-relative'>
                            <img className='img-fluid image-border-sec' src="./images/sec-page.jpeg" alt="Sec Image" />
                            <img className='col-2 col-md-2 overlay-img up-line' src="./images/up-line.png" alt="Line-design" />
                        </div>
                    </div>
                </div>
        </div>
    )
};